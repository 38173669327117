import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Divider,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd";
import Sider from "antd/lib/layout/Sider";
import cn from "classnames";

import { useRouter } from "next/router";

import { MENUS } from "../utils/const";
import { useAuth } from "../contexts/firebaseContext";
import { useState } from "react";

const LayoutLoginArea = ({ pageTitle, breadcrumbItems, children }) => {
  const router = useRouter();
  const [collapsed, setCollapsed] = useState(true);
  const { signOut, authUser, isLoading: authUserLoading, refetch } = useAuth();
  const isAdmin = authUser?.is_client_admin;
  const hasAccess = !!authUser;

  const handleMenuClick = ({ key }) => {
    if (key === "logout") {
      signOut();
    } else {
      router.replace(key);
    }
  };

  if (authUserLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout
      hasSider
      className="my-layout"
      style={{
        height: "100vh",
        display: "flex",
      }}
    >
      <Sider
        width={250}
        collapsible
        collapsed={collapsed}
        onCollapse={(val) => setCollapsed(val)}
        breakpoint="md"
        className="h-screen fixed flex flex-col z-10"
        style={{
          height: "100vh",
          position: "fixed",
          zIndex: 10, // Ensure the sider is above other elements
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Top Section: Logo and Menu */}
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography className="text-white font-semibold text-lg p-4 mb-8">
              Subsidies.io
            </Typography>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[router.pathname]}
              items={MENUS()}
              onClick={handleMenuClick}
              inlineCollapsed={collapsed}
            />
          </div>

          {/* Bottom Section: Avatar */}
          <Space
            direction="horizontal"
            align="end"
            className={cn("w-full gap-4", {
              "justify-center": collapsed,
              "pl-6": !collapsed,
            })}
            style={{
              height: "600px",
            }}
          >
            <Avatar
              src={authUser?.photoURL || ""}
              alt={authUser?.display_name || ""}
              icon={<UserOutlined />}
              style={{ verticalAlign: "middle" }} // Center initials vertically
            />
            <Space direction="vertical" hidden={collapsed} className="gap-1">
              <span className="text-white">{authUser?.display_name || ""}</span>
              <span className="text-gray-300 text-sm font-light">
                {isAdmin ? "Admin" : "User"}
              </span>
            </Space>
          </Space>
        </div>
      </Sider>
      <Layout
        className={cn(
          "site-layout min-h-screen py-6 px-3 sm:px-4 lg:px-8 transition-all ease-in-out duration-200",
          {
            "ml-60": !collapsed,
            "ml-20": collapsed,
          }
        )}
        style={{
          height: "100%",
          marginLeft: collapsed ? 80 : 250, // Adjusted for Ant Design's default collapsed width
          marginTop: "30px",
          width: "100%",
          paddingLeft: collapsed ? "16px" : "24px",
        }}
      >
        <Row>
          <Col span={24}>
            <Typography.Title level={2}>{pageTitle}</Typography.Title>
            <Divider />
          </Col>
        </Row>

        {!hasAccess ? (
          <Row className="w-full mt-32" align="middle" justify="center">
            <Col>
              <Typography>
                Oops.. You are not allowed to access this page!
              </Typography>
            </Col>
          </Row>
        ) : (
          <>{children}</>
        )}
      </Layout>
    </Layout>
  );
};

export default LayoutLoginArea;
