import axios from "axios";
import { getFromLocalStorage } from "../components/utils/localStorage";

function createAuthAxios(baseURL) {
  const instanceAxios = axios.create({
    baseURL: baseURL,
    // withCredentials: true,
    timeout: 600000,
  });

  instanceAxios.interceptors.request.use((config) => {
    const curConfig = { ...config };

    const { token } = getFromLocalStorage();
    try {
      curConfig.headers.Authorization = `Bearer ${token}`;
    } catch (e) {}
    return curConfig;
  });

  return instanceAxios;
}

const Fetcher = {
  createAuthAxios,
};

export default Fetcher;
